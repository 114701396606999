import React, { Component } from "react"
import styled from "styled-components"
import logo from "images/dg-logo-shadow.png"
import arrow from "images/Icon-Arrow.png"
import mobileHeader from "images/bg-header-576.jpg"

const HeroContainer = styled.div`
    position: relative;
    z-index: 0;
    width: 100%;

    /* (original height / original width) x new width = new height */
    padding-bottom: calc(((815 / 1921) * 100vw) - 20px);

    video {
        position: fixed;
        width: 100%;
        top: 0;
        left: 0;
    }
`

const Logo = styled.img`
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 5rem;
`

const Arrow = styled.img`
    position: absolute;
    left: 50%;
    transform: translate(0, -50%);
    bottom: 2rem;
    animation: bounce 1.2s cubic-bezier(.36,-.21,.98,1.21) infinite;

    @keyframes bounce {
        0% {
            transform: translate(-50%, 0);
        }
        50% {
            transform: translate(-50%, 10px);
        }
        100% {
            transform: translate(-50%, 0);
        }
    }
`

class Hero extends Component {
    render() {
        return(
            <>
                <HeroContainer className="d-none d-md-block">
                    <video loop autoPlay muted playsInline>
                        <source src="https://player.vimeo.com/external/266766185.sd.mp4?s=8a917bc5052053b76b85374e0472b45207d87923&amp;profile_id=165" type="video/mp4" />
                    </video>
                    <Logo src={logo} alt="Dreamgirls the Musical on Tour" />
                    <Arrow src={arrow} alt="" />
                </HeroContainer>
                <img src={mobileHeader} alt="Dreamgirls the Musical on Tour" className="d-md-none d-block mx-auto" />
            </>
        )
    }
}

export default Hero