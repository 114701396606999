import styled, { css } from "styled-components"
import { media } from "utils/Media"
import underline from "images/underline.png"

const Text = styled.p`
    font-family: ${props => props.theme.font.family.body};
    font-size: ${props => props.theme.font.size.base};

    ${props => props.special && css`
        font-family: ${props => props.theme.font.family.condensedBold};
        margin-bottom: 2rem;
        position: relative;

        &:after {
            content: "";
            position: absolute;
            left: 50%;
            bottom: -.5rem;
            width: 100%;
            height: 10px;
            background-image: url("${underline}");
            transform: translateX(-50%);
            background-repeat: no-repeat;
            background-position: 50% 50%;
            z-index: 1;
        }
    `}

    ${props => props.as === "h1" && css`
        font-size: ${props => props.theme.font.h1.size};
    `}

    ${props => props.white && css`
        color: ${props => props.theme.colors.white};
    `}

    ${props => props.whiteOff && css`
        color: ${props => props.theme.colors.whiteOff};
    `}

    ${props => props.cream && css`
        color: ${props => props.theme.colors.cream} !important;
    `}

    ${props => props.black && css`
        color: ${props => props.theme.colors.black};
    `}

    ${props => props.red && css`
        color: ${props => props.theme.colors.red};
    `}

    ${props => props.blackOff && css`
        color: ${props => props.theme.colors.blackOff};
    `}

    ${props => props.grey && css`
        color: ${props => props.theme.colors.grey};
    `}

    ${props => props.yellow && css`
        color: ${props => props.theme.colors.yellow};
    `}

    ${props => props.medium && css`
        font-family: ${props => props.theme.font.family.medium};
    `}

    ${props => props.bold && css`
        font-family: ${props => props.theme.font.family.bold};
    `}

    ${props => props.xl && css`
        font-size: ${props => props.theme.font.h3.size};

        @media ${media.md} {
            font-size: ${props => props.theme.font.h2.size};
        }
    `}

    ${props => props.lg && css`
        font-size: ${props => props.theme.font.size.lg};
    `}

    ${props => props.md && css`
        font-size: ${props => props.theme.font.size.md};
    `}

    ${props => props.sm && css`
        font-size: ${props => props.theme.font.size.sm};
    `}

    ${props => props.xs && css`
        font-size: ${props => props.theme.font.size.xs};
    `}

    ${props => props.size && css`
        font-size: ${props.size}rem;
    `}

    ${props => props.italic && css`
        font-style: italic;
    `}
    
    ${props => props.uppercase && css`
        text-transform: uppercase;
    `}

    ${props => props.center && css`
        text-align: center;
    `}

    ${props => props.right && css`
        text-align: right;
    `}

    ${props => props.shadow && css`
         text-shadow: 1px 1px #000000;
    `}

    ${props => props.condensed && css`
        font-family: ${props => props.theme.font.family.condensed};
    `}

    ${props => props.condensedMedium && css`
        font-family: ${props => props.theme.font.family.condensedMedium};
    `}

    ${props => props.condensedBold && css`
        font-family: ${props => props.theme.font.family.condensedBold};
    `}
`

export default Text