import React, { Component } from "react"
import ReactPlayer from 'react-player'
import styled, { css } from "styled-components"
import { Container, Row, Col } from "reactstrap"
import { media } from "utils/Media"
import Text from "components/shared/Text"
import Button from "components/shared/Button"
import bgImageMob from "images/music-player-image-mob.png"
import bgImage from "images/music-player-image-desktop.png"
import playerBg from "images/home-playbg.png"
import upArrow from "images/up-arrow.png"
import downArrow from "images/down-arrow.png"
import playIcon from "images/play-icon.png"
import pauseIcon from "images/pause.png"
import { CircularProgressbar } from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'

import song1 from "./And-I-Am-Telling-You-Im-Not-Going.mp3"
import song2 from "./Dreamgirls-Soundbed-MASTERED-VERSION.mp3"
import song3 from "./Original-London-Cast-Of-Dreamgirls-Dreamgirls-Original-London-Cast-Recording-10-Listen.mp3"

import BackgroundImg from '../../images/player-background.png'
import BackgroundImgMob from '../../images/music-player-background-mob.png'


const PlayerStyled = styled.section`
    position: relative;
    z-index: 1;
    background: #26033A;
    padding-bottom: 2rem;
    .background {
        position: relative;
        text-align: center;
        width: 100%;
        .desktop {
            display: none;
            @media ${media.sm} {
                display: block;
            }
        }
        .mobile {
            img {
                width: 100%;
            }
            @media ${media.sm} {
                display: none;
            }
        }
      
        .overlay {
            width: 100%;
            height: 10rem;
            bottom: 0;
            left: 0;
            right: 100%;
            position: absolute;
            background: linear-gradient(180deg, rgba(196, 196, 196, 0) 0%, rgba(34, 7, 51, 0.5) 60.42%, #220733 100%);


        }
    }
    .text {
        margin-top: -3rem!important;
        position: relative;
        z-index: 4;
    }
    .player {
        margin: 1.5rem auto;    
        max-width: 450px;
    
    }

`

const ReactPlayerStyled = styled(ReactPlayer)`
    position: absolute;
    max-width: 100%;
    opacity: 0;
    z-index: 0;
`

const BackgroundContainer = styled(Container)`
    background-color: ${props => props.theme.colors.purpleDark};
    background-image: url("${bgImageMob}");
    position: relative;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center top;
    padding: 20rem 0 4rem 0;

    @media ${media.md} {
        padding: 4rem 0;
        background-size: cover;
        background-image: url("${bgImage}");
    }
`

const PlayerWrap = styled.div`
    background-image: url("${playerBg}");
    background-size: 100% 100%;
    padding: 2rem;
    margin: 0 2rem;

    @media ${media.md} {
        padding: 3rem;
        margin: 0;
    }
`

const SongButton = styled.button`
    background-color: transparent;
    border: none;
    margin-bottom: 3rem;
    display: block;

    ${props => props.prev && css`
        margin-bottom: 0;
    `}
`

const PlayButton = styled.button`
    background-color: transparent;
    border: none;
    height: 62px;
    position: relative;
    z-index: 1;
`

const CircularProgressbarStyled = styled(CircularProgressbar)`
    position: absolute;
    top: 0;
    left: -3px;
    height: 65px;
    width: 65px;
    z-index: 0;
`

class MusicPlayer extends Component {

    constructor(props) {
        super(props)

        this.state = {
            songs: [
                {
                    name: "And I'm Telling You",
                    file: song1
                },
                {
                    name: "Listen",
                    file: song2
                },
                {
                    name: "Dreamgirls",
                    file: song3
                }
            ],
            activeSong: 0,
            pip: false,
            controls: false,
            light: false,
            volume: 0.8,
            muted: false,
            played: 0,
            loaded: 0,
            duration: 0,
            playbackRate: 1.0,
            loop: false,
            collapse: false,
            percentage: 0
        }

        this.toggleAudioPlaying = this.toggleAudioPlaying.bind(this)
        this.changeSong = this.changeSong.bind(this)
    }

    toggleAudioPlaying() {
        this.setState({
            playing: !this.state.playing
        })
    }

    changeSong(direction) {
        const { songs, activeSong } = this.state
        const songCount = songs.length - 1
        let nextSong
        
        if (direction) {
            //Next
            nextSong = activeSong + 1
            nextSong = nextSong > songCount ? 0 : nextSong
        } else {
            //prev
            nextSong = activeSong - 1
            nextSong = nextSong < 0 ? songCount : nextSong
        }

        this.setState({
            activeSong: nextSong
        })
    }

    handleProgress = state => {
        console.log('onProgress', state)
        // We only want to update time slider if we are not currently seeking
        if (!this.state.seeking) {
            // { played: 0.12, playedSeconds: 11.3, loaded: 0.34, loadedSeconds: 16.7 }
            this.setState(state)
            console.log(state)
        }
    }

    render() {
        const { songs, activeSong, playing, playbackRate } = this.state

        const config = {
            file: {
                debug: true,
                forceAudio: true,
                attributes: {
                    disablePictureInPicture: true
                }
            }
        }

        return(
           <PlayerStyled>
                <div className="background">
                    <div className="desktop">
                        <img src={BackgroundImg} alt="" />
                    </div>
                    <div className="mobile">
                        <img src={BackgroundImgMob} alt="" />
                    </div>
                    <div className="overlay" />
                </div>
                <div className="text">
                    <h2 className="text-center mb-0">THE DAZZLING SOUL SENSATION THAT <br /> 'DRIVES AUDIENCES WILD'</h2>
                    <Text condensed center>Variety</Text>
                </div>
                <div className="player">
                    <PlayerWrap>
                    <Text center xl condensedBold><em>'Listen'</em> to music from the show</Text>

                    <ReactPlayerStyled
                        ref={this.ref}
                        url={songs[activeSong].file}
                        config={config}
                        controls
                        playbackRate={playbackRate}
                        onProgress={this.handleProgress}
                        playing={playing}
                        // onBufferEnd={() => this.setState({ playing: true })}
                        width="100%"
                        height="100px"
                        // muted={!playing}
                        // onError={this.handleError}
                    />

                    <Row className="align-items-center justify-content-center">
                        <Col xs={12} sm={3} className="text-center">
                            <PlayButton onClick={this.toggleAudioPlaying}>
                                {playing ? (
                                    <img src={pauseIcon} alt="Pause" />
                                ) : (
                                    <img src={playIcon} alt="Play" />
                                )}
                            </PlayButton>
                            <CircularProgressbarStyled
                                strokeWidth={5}
                                value={this.state.played * 100}
                                styles={{
                                    path: {
                                        // Path color
                                        stroke: `rgba(255, 255, 255, ${this.state.played * 100})`,
                                        // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                                        strokeLinecap: 'butt',
                                        transition: 'stroke-dashoffset 0.1s ease 0s',
                                    },
                                    // Customize the circle behind the path, i.e. the "total progress"
                                    trail: {
                                        // Trail color
                                        stroke: 'transparent',
                                    },
                                }}
                            />
                        </Col>
                        <Col xs={7}>
                            <Text lg>{songs[activeSong].name}</Text>
                        </Col>
                        <Col xs="auto" sm={2}>
                            <SongButton next onClick={() => this.changeSong(true)}>
                                <img src={upArrow} alt="Up Arrow" />
                            </SongButton>
                            <SongButton prev onClick={() => this.changeSong(false)}>
                                <img src={downArrow} alt="Down Arrow" />
                            </SongButton>
                        </Col>
                    </Row>

                    <div className="text-center pt-4">
                        <Button href="https://dreamgirlswestendstore.com/index.php?page=products&section=Music" rel="noopener noreferrer" target="_blank" center>
                            Buy now
                        </Button>
                    </div>
                    </PlayerWrap>
                </div>
           </PlayerStyled>
        )
    }
}

export default MusicPlayer




