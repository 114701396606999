import React from "react"
import { Link } from "gatsby"
import { Container, Row, Col } from "reactstrap"
import Layout from "components/Layout/Layout"
import Hero from "components/Hero"
import Text from "components/shared/Text"
import ColouredBg from "components/shared/ColouredBg"
import MusicPlayer from "components/MusicPlayer/MusicPlayer"
import Button from "components/shared/Button"
import stars5 from "images/5stars.png"
import stars4 from "images/4stars.png"


const AboutPage = () => {
    return(
        <Layout
            slug="/"
            title="Home">
            <Hero />

            <ColouredBg>
                <Container fluid className="py-5">
                    <Row className="text-center pb-4">
                        <Col lg={4} className="d-none d-lg-block text-center">
                            <img src={stars5} alt="5 Stars" /><br />
                            <Text lg className="mb-0">'A glittering tour de force of disco and soul'</Text>
                            <Text sm>Daily Express</Text>
                            <img src={stars4} alt="4 Stars" /><br />
                            <Text lg className="mb-0">'Dreamgirls has soul in all the right places'</Text>
                            <Text sm>Sunday Times</Text>
                            <img src={stars4} alt="4 Stars" /><br />
                            <Text lg className="mb-0">'A high energy show that delivers with knockout energy'</Text>
                            <Text sm>Financial Times</Text>
                        </Col>
                        <Col lg={4}>
                            <Text as="div" lg className="mb-4 mt-4">
                                <p>The UK tour has now closed.</p>
                                <p>For future updates from Sonia Friedman Productions, the producers of Dreamgirls and some of the most successful theatre productions around the world, please join their mailing list <a href="https://www.soniafriedman.com/about/join-our-mailing-list" target="_blank" rel="noopener noreferrer">here</a>.</p>
                            </Text>
                            {/*<Button color="gradient" to="/tour-dates/" as={Link}>*/}
                            {/*    TOUR DATES*/}
                            {/*</Button>*/}
                        </Col>
                        <Col lg={4}>
                            <img src={stars5} alt="5 Stars" /><br />
                            <Text lg className="mb-0">'Dazzling. A lavish musical that's at all times exhilarating'</Text>
                            <Text sm>Evening Standard</Text>
                            <img src={stars4} alt="4 Stars" /><br />
                            <Text lg className="mb-0">'A full-throttle fast-moving blast of a production'</Text>
                            <Text sm>The Independent</Text>
                            <img src={stars4} alt="4 Stars" /><br />
                            <Text lg className="mb-0">'A fantastically entertaining ride on a showbiz rollercoaster'</Text>
                            <Text sm>The Guardian</Text>
                        </Col>
                    </Row>
                </Container>
            </ColouredBg>

            <MusicPlayer />
        </Layout>
    )
}

export default AboutPage
